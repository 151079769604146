import { ProjectData, ProjectDraft } from "@/models/project";
import { FirebaseUser } from "@/models/users";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import FormData from "form-data";
import { Navigation } from "./common";
import ServiceController from "./service";

export default class ProjectsController extends ServiceController {
  static async addProject(
    projectDraft: ProjectDraft,
    user: FirebaseUser
  ): Promise<void> {
    try {
      const token = (await user?.getIdToken()) ?? "";
      const data = new FormData();
      data.append("name", projectDraft.name);
      data.append("description", projectDraft.description);
      data.append("link", projectDraft.link);
      data.append("picture", projectDraft.picture, projectDraft.picture.name);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": `multipart/form-data; boundary=${data.getBoundary}`,
        },
        onUploadProgress: (progressEvent) => console.log(progressEvent.loaded),
      } as AxiosRequestConfig;
      await axios.post(`${ProjectsController.HOST}/projects/add`, data, config);
      Navigation.goTo("Missions");
    } catch (error) {
      const axiosError = error as AxiosError;
      ProjectsController.translateError(axiosError);
    }
  }

  static async updateProject(
    projectId: string,
    projectDraft: ProjectDraft,
    user: FirebaseUser
  ): Promise<void> {
    try {
      const token = (await user?.getIdToken()) ?? "";
      const data = new FormData();
      data.append("name", projectDraft.name);
      data.append("description", projectDraft.description);
      data.append("link", projectDraft.link);
      if (projectDraft.picture) {
        data.append("picture", projectDraft.picture, projectDraft.picture.name);
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": `multipart/form-data; boundary=${data.getBoundary}`,
        },
        params: {
          projectId: projectId,
        },
        onUploadProgress: (progressEvent) => console.log(progressEvent.loaded),
      } as AxiosRequestConfig;
      await axios.put(
        `${ProjectsController.HOST}/projects/update`,
        data,
        config
      );
      Navigation.goTo("Missions");
    } catch (error) {
      const axiosError = error as AxiosError;
      ProjectsController.translateError(axiosError);
    }
  }

  static async deleteProject(
    projectId: string,
    user: FirebaseUser
  ): Promise<void> {
    try {
      const token = (await user?.getIdToken()) ?? "";
      const headers = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          projectId: projectId,
        },
      } as AxiosRequestConfig;
      await axios.delete(`${ProjectsController.HOST}/projects/remove`, headers);
      Navigation.goTo("Missions");
    } catch (error) {
      const axiosError = error as AxiosError;
      ProjectsController.translateError(axiosError);
    }
  }

  static async fecthAll(
    user: FirebaseUser
  ): Promise<ProjectData[] | undefined> {
    try {
      const token = (await user?.getIdToken()) ?? "";
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      } as AxiosRequestConfig;
      const response = await axios.get(
        `${ProjectsController.HOST}/projects/admin/all`,
        config
      );
      const projects: ProjectData[] = response.data.projects;
      return projects;
    } catch (error) {
      const axiosError = error as AxiosError;
      ProjectsController.translateError(axiosError);
    }
  }
}
