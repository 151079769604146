<template>
  <a-spin :spinning="loadingState" :delay="250">
    <div class="edit-header">
      <div class="title">
        <div class="action-button users" @click="goBack">
          <img src="@/assets/actions/back-black.svg" alt="Ir a Inicio" />
        </div>
        <h1 class="global-title">{{ titleText }} Proyecto</h1>
      </div>
      <a-popconfirm
        placement="leftTop"
        title="¿Está seguro de que desea eliminar este proyecto?"
        ok-text="Sí, eliminar proyecto"
        cancel-text="No, mantener proyecto"
        @confirm="deleteProject"
        v-if="edit"
      >
        <div class="action-button delete">
          <img src="@/assets/actions/delete-icon.svg" alt="Ir a Inicio" />
        </div>
      </a-popconfirm>
    </div>
    <div class="splitted">
      <Form
        v-model="projectDraft"
        :forUpdate="edit"
        @add-project="addProject"
        @update-project="updateProject"
      />
      <SelectImage
        label="Imagen"
        hint="Seleccionar"
        :prevUrl="prevUrl"
        :reqWidth="480"
        :reqHeight="270"
        v-model="projectDraft.picture"
      />
    </div>
  </a-spin>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Form from "@/components/projects/ProjectForm.vue";
import SelectImage from "@/components/common/ImageSelect.vue";

import { Navigation, Store } from "@/controllers/common";
import Project, { ProjectDraft } from "@/models/project";

import Authentication from "@/controllers/auth";
import ProjectsController from "@/controllers/projects";
import { FirebaseUser } from "@/models/users";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "Profile",
  setup() {
    useMeta({
      title: "Editar Proyecto",
      description: "Editar información de proyecto misionero.",
    });
  },
  components: {
    Form,
    SelectImage,
  },
  beforeMount() {
    var project = Store.getValue("edition/project") as Project;
    if (project) {
      this.projectDraft = project.toDraft();
      this.reference = project.reference;
      this.prevUrl = project.picture as string;
      this.edit = true;
    } else {
      this.$router.replace({ name: "AddProject" });
    }
  },
  beforeUnmount() {
    Store.update("edition/storeProject", undefined);
  },
  data() {
    return {
      loadingState: false,
      edit: false,
      projectDraft: {} as ProjectDraft,
      prevUrl: "",
      reference: "",
    };
  },
  computed: {
    titleText(): string {
      return this.edit ? "Editar" : "Agregar";
    },
    currentUser(): FirebaseUser {
      return Authentication.currentUser();
    },
  },
  methods: {
    goBack() {
      Navigation.goBack();
    },
    async addProject(): Promise<void> {
      this.loadingState = true;
      await ProjectsController.addProject(this.projectDraft, this.currentUser);
      this.loadingState = false;
    },
    async updateProject(): Promise<void> {
      this.loadingState = true;
      await ProjectsController.updateProject(
        this.reference,
        this.projectDraft,
        this.currentUser
      );
      this.loadingState = false;
    },
    async deleteProject(): Promise<void> {
      this.loadingState = true;
      await ProjectsController.deleteProject(this.reference, this.currentUser);
      this.loadingState = false;
    },
  },
});
</script>

<style scoped>
.verse-preview {
  color: black;
  font-size: 1.5em;
  font-family: "Roboto", sans-serif;
  margin: 0 10%;
  align-items: center;
  display: flex;
}

@media only screen and (min-width: 768px) {
  .edit-header {
    width: calc(50% - 2rem);
  }
}
</style>
