<template>
  <BaseCard :actionable="true">
    <div>
      <h3 class="name">{{ data.name }}</h3>
      <h3 class="message">{{ data.description }}.</h3>
    </div>
  </BaseCard>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import BaseCard from "@/components/common/BaseCard.vue";

import { TableData } from "@/models/project";

export default defineComponent({
  name: "VenueCard",
  components: {
    BaseCard,
  },
  props: {
    data: {
      type: Object as PropType<TableData>,
      required: true,
    },
  },
  data() {
    return {
      menuState: false,
    };
  },
});
</script>

<style scoped>
.cnt-card {
  font-size: 1.2rem;
}

h3.name {
  text-align: center;
  font-weight: var(--f-semibold);
}

h3.message {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
