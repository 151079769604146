
import { defineComponent } from "vue";

import Form from "@/components/projects/ProjectForm.vue";
import SelectImage from "@/components/common/ImageSelect.vue";

import { Navigation, Store } from "@/controllers/common";
import Project, { ProjectDraft } from "@/models/project";

import Authentication from "@/controllers/auth";
import ProjectsController from "@/controllers/projects";
import { FirebaseUser } from "@/models/users";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "Profile",
  setup() {
    useMeta({
      title: "Editar Proyecto",
      description: "Editar información de proyecto misionero.",
    });
  },
  components: {
    Form,
    SelectImage,
  },
  beforeMount() {
    var project = Store.getValue("edition/project") as Project;
    if (project) {
      this.projectDraft = project.toDraft();
      this.reference = project.reference;
      this.prevUrl = project.picture as string;
      this.edit = true;
    } else {
      this.$router.replace({ name: "AddProject" });
    }
  },
  beforeUnmount() {
    Store.update("edition/storeProject", undefined);
  },
  data() {
    return {
      loadingState: false,
      edit: false,
      projectDraft: {} as ProjectDraft,
      prevUrl: "",
      reference: "",
    };
  },
  computed: {
    titleText(): string {
      return this.edit ? "Editar" : "Agregar";
    },
    currentUser(): FirebaseUser {
      return Authentication.currentUser();
    },
  },
  methods: {
    goBack() {
      Navigation.goBack();
    },
    async addProject(): Promise<void> {
      this.loadingState = true;
      await ProjectsController.addProject(this.projectDraft, this.currentUser);
      this.loadingState = false;
    },
    async updateProject(): Promise<void> {
      this.loadingState = true;
      await ProjectsController.updateProject(
        this.reference,
        this.projectDraft,
        this.currentUser
      );
      this.loadingState = false;
    },
    async deleteProject(): Promise<void> {
      this.loadingState = true;
      await ProjectsController.deleteProject(this.reference, this.currentUser);
      this.loadingState = false;
    },
  },
});
