export const ProjectsColumns = [
  {
    title: "Nombre",
    dataIndex: "name",
    key: "name",
    width: "15%",
  },
  {
    title: "Descripción",
    dataIndex: "description",
    key: "description",
    ellipsis: true,
  },
  {
    title: "Acción",
    dataIndex: "action",
    key: "action",
    slots: { customRender: "action" },
    width: "10%",
  },
];

export interface ProjectData {
  name: string;
  description: string;
  link: string;
  picture: string;
  reference: string;
}

export interface ProjectDraft {
  name: string;
  description: string;
  link: string;
  picture: File;
}

export interface ProjectUpdate {
  name: string;
  description: string;
  link: string;
}

export interface TableData {
  key?: number;
  name: string;
  description: string;
  link: string;
}

export default class Project {
  name: string;
  description: string;
  link: string;
  picture: string | File;
  reference: string;

  constructor(data: ProjectData) {
    this.name = data.name;
    this.description = data.description;
    this.link = data.link;
    this.picture = data.picture;
    this.reference = data.reference;
  }

  toDraft(): ProjectDraft {
    return {
      name: this.name,
      description: this.description,
      link: this.link,
    } as ProjectDraft;
  }

  getTableContent(): TableData {
    return {
      name: this.name,
      description: this.description,
      link: this.link,
    };
  }
}
