
import ProjectCard from "@/components/projects/ProjectCard.vue";

import { defineComponent } from "vue";

import BaseField from "@/components/common/BaseField.vue";
import BaseButton from "@/components/common/BaseButton.vue";

import { Navigation, ResponsiveManager, Store } from "@/controllers/common";
import Project, {
  ProjectData,
  ProjectsColumns,
  TableData,
} from "@/models/project";

import Authentication from "@/controllers/auth";
import ProjectsController from "@/controllers/projects";
import { FirebaseUser } from "@/models/users";
import { ScreenType } from "@/models/common";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "Projects",
  setup() {
    useMeta({
      title: "Proyectos Misioneros",
      description: "Proyectos misioneros del Centro Cristiano.",
    });
  },
  components: {
    ProjectCard,
    BaseField,
    BaseButton,
  },
  created() {
    if (this.currentUser) {
      this.fetchingData = true;
      ProjectsController.fecthAll(this.currentUser).then((projectsData) => {
        this.fetchingData = false;
        if (projectsData) {
          const data = projectsData as ProjectData[];
          let projects: Project[] = [];
          for (const project of data) {
            projects.push(new Project(project));
          }
          this.projects = projects;
        }
      });
    }
  },
  data() {
    return {
      fetchingData: false,
      filterName: "",
      projects: [] as Project[],
    };
  },
  computed: {
    isMobile(): boolean {
      var screen = ResponsiveManager.retrieveScreenSize();
      return screen === ScreenType.mobile;
    },
    columns(): Array<Record<string, unknown>> {
      return ProjectsColumns;
    },
    currentUser(): FirebaseUser {
      return Authentication.currentUser();
    },
    dataSource(): Array<TableData> {
      var key = 0;
      var dataSource: Array<TableData> = [];
      for (const project of this.projects) {
        var projectContent = project.getTableContent();
        projectContent.key = key;
        dataSource.push(projectContent);
        key = key + 1;
      }
      return dataSource;
    },
    tableContent(): Array<TableData> {
      if (this.filterName === "") {
        return this.dataSource;
      } else {
        var rows: Array<TableData> = [];
        for (const data of this.dataSource) {
          if (
            (data.name as string)
              .toLowerCase()
              .includes(this.filterName.toLowerCase())
          ) {
            rows.push(data);
          }
        }
        return rows;
      }
    },
  },
  methods: {
    addProject(): void {
      Navigation.goTo("AddProject");
    },
    editProject(index: number): void {
      Store.update("edition/storeProject", this.projects[index]);
      Navigation.goTo("EditProject");
    },
  },
});
